import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatriculaService } from '@core/services/matricula.service';
import { StorageService } from '@core/services/storage.service';
import { UtilPermissoes } from '@core/utils/permissoes';
import { NgxPermissionsService } from 'ngx-permissions';
import { MSG_SEM_ACESSO_INFORMACOES_DO_SISTEMA } from 'src/app/shared/utils/constants';
import { SweetalertCustom } from 'src/app/shared/utils/sweetalert-custom';

@Component({
  selector: 'app-selecionar-matricula',
  templateUrl: './selecionar-matricula.component.html',
  styleUrls: ['./selecionar-matricula.component.scss']
})
export class SelecionarMatriculaComponent implements OnInit {
  public matriculas;
  profissionalMatriculaId;
  dados;

  constructor(
    public matriculaService: MatriculaService,
    public router: Router,
    private storageService: StorageService,
    private permissionsService: NgxPermissionsService
  ) {}

  ngOnInit() {
    this.matriculas = this.storageService.getFromLocalStorage('profissionalMatriculas', true);
  }

  getMatriculaSelected(profissionalMatriculaId) {
    this.profissionalMatriculaId = profissionalMatriculaId;
    this.getPerfilUsuarioByMatricula();
  }

  getPerfilUsuarioByMatricula() {
    this.matriculaService.getDadosUsuarioByMatricula(this.profissionalMatriculaId)
    .subscribe((response) => {
      this.setStorageValues(response);
      this.setPermissions(response);
      this.router.navigate([ '/' ]);
    }, () => {
      SweetalertCustom.newShowAlertConfirm('warning', 'Atenção',
      MSG_SEM_ACESSO_INFORMACOES_DO_SISTEMA,
       'Voltar')
        .then((resp) => {
          if (resp.isConfirmed) {
            this.router.navigate([ '/' ]);
          }
        });
    });
  }

  private setStorageValues(response: any) {
    const perfilAcesso = response.perfilAcesso.toUpperCase();
    this.storageService.setInLocalStorage('perfilAcesso', perfilAcesso);
    this.storageService.setInSessionStorage('perfilAcesso', perfilAcesso);
    this.storageService.setInSessionStorage('profissionalMatriculaId', this.profissionalMatriculaId);
    this.storageService.setInLocalStorage('usuario-matricula', response);
    this.storageService.setInLocalStorage('funcao', response.funcao);
    this.storageService.setInSessionStorage('usuario-nome-setor', response.nomeSetor);
    this.storageService.setInSessionStorage('usuario-codigo-setor', response.codigoSetor);
    this.storageService.setInSessionStorage('temAcessoAFerias', response.podeAcessarFerias);
    this.storageService.setInSessionStorage('usuarioChefeImediato', response.usuarioChefeImediato);
    this.storageService.setInSessionStorage('nome', response.data?.nome);
  }

  private setPermissions(response: any) {
    const perfilAcesso = response.perfilAcesso;
    UtilPermissoes.SetarPermissoes(perfilAcesso, this.storageService, this.permissionsService, response.permissoes);
  }
}
