import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import moment from 'moment';
import { Util } from '../../utils/util';
@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() name: string = '';
  @Input() customClass: any = '';

  @Input() view: string = 'date';
  @Input() dateFormat: string = 'dd/mm/yy';
  @Input() placeholder: string = 'dd/mm/aaaa';

  @Input() maxDate: Date = null;
  @Input() minDate: Date = null;

  @Input() yearRange = '2000:2030';
  @Input() showButtonBar = 'true';

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onChange = new EventEmitter<any>();
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onFocusOut = new EventEmitter<any>();

  inputModel: Date = null;

  locale = Util.getBrasilCalendarLocale();

  get formControl(): FormControl {
    return this.form.get(this.name) as FormControl;
  }

  get disabled() {
    return this.formControl?.disabled;
  }

  ngOnInit(): void {
    this.formControl?.valueChanges.subscribe((value) => {
      this.inputModel =
        typeof value === 'string' ? Util.formatStringToDate(value) : value;
    });
    this.inputModel =
      this.formControl?.value &&
      typeof this.formControl?.value === 'string' ?
      Util.formatStringToDate(this.formControl?.value) : this.formControl?.value;
  }

  change() {
    this.formControl?.markAsTouched();
    this.onChange.emit(this.formControl?.value);
    this.onFocusOut.emit();
  }

  blur() {
    this.formControl?.markAsTouched();
    this.onFocusOut.emit(this.formControl?.value);
  }

  getValueFormatted(value: any) {
    return value ? moment(value).format('YYYY-MM-DD HH:mm:SS') : '';
  }

  validateDateLength(event: any) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.value.length > 10) {
      inputElement.value = inputElement.value.slice(0, 10);
    }
  }
}
