import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

  get anoAtual() {
    const dataAtual = new Date();
    const anoatual = dataAtual.getFullYear();
    return anoatual;
  }

}
