import { Component, Input, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { ArquivoCreateDto } from "@core/dto/arquivo/arquivo-create-dto";


@Component({
  selector: 'app-upload-photo',
  templateUrl: './upload-photo.component.html',
  styleUrls: ['./upload-photo.component.scss'],
})
export class UploadPhotoComponent {
  @Input() form: FormGroup;
  @Input() name: string;
  @Input() submitText = 'Enviar Imagem';
  @Input() imgAlt = 'Imagem de Perfil';
  @Input() id: string = null;
  @Input() accept = '.png, .jpg, .jpeg';

  isFirstLoad = true;
  acao = 'NOTHING';
  file: any;

  @ViewChild('inputPhoto') inputRef;

  constructor(private domSanitizer: DomSanitizer) {}

  get formControl() {
    return this.form?.get(this.name);
  }

  get hasImage(): boolean {
    return !!this.imgSource;
  }

  get disabled(): boolean {
    return this.formControl?.disabled;
  }

  async onSelectFile(event) {
    if (this.disabled) { return; }

    this.file = (event.target as HTMLInputElement).files[0];
    const base64 = await this.getBase64FromFile(this.file);
    const arquivoDto = new ArquivoCreateDto({
      id: this.id,
      base64,
      extensao: '.' + this.file.type.split('/')[1],
      acao: this.id ? 'EDIT' : 'CAD',
      nome: this.file.name.split('.')[0],
    });
    this.formControl.setValue(arquivoDto);
  }

  get imgSource() {
    return this.formControl.value ? this.domSanitizer.bypassSecurityTrustUrl(`data:image/jpeg;base64, ${this.formControl.value?.base64}`) : '';
  }

  async getBase64FromFile(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>  resolve((reader.result as string).split(';base64,').pop());
      reader.onerror = (error) => reject(error);
    });
  }

  clear() {
    this.formControl.setValue(null);
    this.file = null;
    this.inputRef.nativeElement.value = '';
  }
}
