import { HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalAvisoGeralComponent } from '@core/layouts/componentes/modal-aviso/modal-aviso-geral.component';
import { ArquivoService } from '@core/services/arquivo.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MSG_IMPORTACAO_PROFISSIONAL } from '@shared/utils/constants';
import { Util } from '../../utils/util';

@Component({
  selector: 'app-upload-large-file',
  templateUrl: './upload-large-file.component.html'
})
export class UploadLargeFileComponent {
  @Input() form: FormGroup = new FormGroup({});
  @Input() accept: string = '.pdf,.xlsx,.DBF';
  @Input() name = 'file';
  @Input() mdButton = false;
  @Input() disabled = false;
  @Input() endpoint = '';
  @Output() uploaded = new EventEmitter();
  @Output() atualizarListagem = new EventEmitter();

  public file: File;
  private progress: number = 0.0;

  constructor(
    private modalService: NgbModal,
    private arquivoService: ArquivoService,
    public activeModal: NgbActiveModal
  ) {}

  public onSelectFile(event: Event): void {
    // So seleciona se ainda nao tem arquivo selecionado
    if (this.file) {
      return;
    }

    // Pega o arquivo selecionado
    this.file = (event.target as HTMLInputElement).files[0];

    if (this.isValidFile(this.file)) {
      this.form.controls[this.name].patchValue(this.file);
    } else {
      this.clear();
    }
  }

  private isValidFile(file: File) {
    const extension = `.${file.name.split('.').pop()?.toLocaleLowerCase()}`;
    const acceptedTypes = this.accept.split(',');
    if (extension && !acceptedTypes.includes(extension)) {
      const modalRef = Util.openModal(
        this.modalService,
        ModalAvisoGeralComponent,
        'lg',
        { centered: true }
      );
      modalRef.componentInstance.message = `Não é possível adicionar documentos em outros formatos. Por favor, verifique se o documento que deseja enviar está no formato permitido (${this.getTxtExtensions()}).`;
      modalRef.componentInstance.isOneButton = true;
      modalRef.componentInstance.nameButton = 'Ok';
      return false;
    } else {
      return true;
    }
  }

  private getTxtExtensions() {
    const acceptedTypes = this.accept.split(',');
    let txt = '';
    acceptedTypes.forEach((type, index) => {
      txt = txt.concat(type.toUpperCase());
      if (acceptedTypes.length - 2 === index) {
        txt = txt.concat(' e ');
      } else if (acceptedTypes.length - 3 >= index) {
        txt = txt.concat(', ');
      }
    });
    return txt;
  }

  public viewAnexo(): boolean {
    return this.file || this.form.get(this.name)?.value;
  }

  public downloadFile(): void {
    const arquivo = this.form.controls[this.name].value;
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(arquivo);
    a.href = url;
    a.download = arquivo.name;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  public clear() {
    this.form.controls[this.name].patchValue('');
    this.file = undefined;
  }

  public upload(formData?: FormData): boolean {
    if (!this.file) {
      return false;
    }

    this.arquivoService.upload(this.endpoint, this.file, formData || new FormData()).subscribe({
      next: (event) => {
        if (event) {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              if (event.total) {
                this.progress = Math.round((100 * event.loaded) / event.total);
              }
              break;
            case HttpEventType.Response:
              this.progress = 0;
              this.uploaded.emit({
                status: event.status,
                body: event.body?.toString() ?? '',
              });
              break;
          }
        }
      },
      error: (error) => {
        this.uploaded.emit({
          status: 'error',
          body: '',
        });
      },
      complete: () => {
        this.progress = 0;
        this.uploaded.emit({
          status: 'success',
          body:  '',
        });
        const modalRef = Util.openModal(
          this.modalService,
          ModalAvisoGeralComponent, 'md', { centered: true }
        );
        modalRef.componentInstance.message = MSG_IMPORTACAO_PROFISSIONAL;
        modalRef.componentInstance.isOneButton = true;
        modalRef.componentInstance.nameButton = 'Ok';
        modalRef.componentInstance.buttonConfirmSubmit.subscribe((res) => {
          if (res) {
            this.closeModal();
            window.location.reload();
          }
        });
      }
    });

    return true;
  }

  public closeModal() {
    this.activeModal.close();
  }
}
