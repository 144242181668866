import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SignalRService } from '@core/services/signalR.service';
import { StorageService } from '@core/services/storage.service';
import { NgxPermissionsService } from "ngx-permissions";
import { MENUS_INFOBAR } from './navbar-infobar-menu.constant';
import { MENUS_NAVBAR, MenuType } from './navbar-menu.constant';

@Component({
  selector: 'app-navbar-auth',
  templateUrl: './navbar-auth.component.html',
  styleUrls: ['./navbar-auth.component.scss'],
})
export class NavbarAuthComponent implements OnInit {
  public menus = [...MENUS_NAVBAR];
  public menusInfoBar = MENUS_INFOBAR;
  public isUserServidor;
  public isUserChefeImediato;
  public usuarioSession;
  public perfilMatriculaSession;
  public buttonClicked: boolean = false;
  public usuarioMatricula: string;

  constructor(
    public router: Router,
    public signalRService: SignalRService,
    private storageService: StorageService,
    private permissionsService: NgxPermissionsService
  ) {  }

  ngOnInit(): void {
    this.usuarioSession = this.storageService.getFromSessionStorage('usuario',true);
    this.perfilMatriculaSession = this.storageService.getFromLocalStorage('usuario-matricula', true);
    this.getCurrentMatricula();
    this.othersMenuPermissions();
    this.formatTitleNotification();
    this.signalRService.getNotificationNotReadService();
    this.signalRService.startConnection();
    this.signalRService.registerOnServerEvents();
  }

  shouldShowNavbarItem(item: MenuType): boolean {
    if (!item.profiles) {
      return true;
    }

    this.shouldShowNavbarItemPermissoes(item);

    return item.profiles.some((profile) =>
      this.perfilMatriculaSession?.perfilAcesso?.toUpperCase().includes(profile?.toUpperCase())
    );
  }

  shouldShowNavbarItemPermissoes(item: MenuType): boolean {
    if (!item.permissoes) {
      return true;
    }

    return item.permissoes.some((permissao) => this.permissionsService.getPermission(permissao));
  }

  othersMenuPermissions() {
    const podeAcessarFerias = this.storageService.getFromSessionStorage('temAcessoAFerias');
    const chefeImediato = this.storageService.getFromSessionStorage('usuarioChefeImediato');
    if (podeAcessarFerias == 'false' && chefeImediato == 'false') {
      const indexToRemove = this.findIndexMenu('/ferias', 'Férias');
      this.removeMenu(indexToRemove);
    }
  }

  findIndexMenu(url: string, label: string): number {
    return this.menus.findIndex((menu) => {
      return menu.url === url && menu.label === label;
    });
  }

  removeMenu(indexRemove: number) {
    if (indexRemove !== -1) {
      this.menus.splice(indexRemove, 1);
    }
  }

  get countNotifications(): number {
    return this.signalRService.notifications.length;
  }

  get countMoreNotifications(): number {
    return this.signalRService.notifications.length - 4;
  }

  redirectToHome() {
    this.router.navigate(['']);
  }

  redirectToHomeWithFocus(item) {
    this.router.navigate(['/central-notificacoes'], {
      queryParams: { id: item.id },
      skipLocationChange: false,
    });
  }

  formatTitleNotification() {
    const notifications = this.signalRService.notifications;
    const characterLimit = 43;
    notifications.forEach((notification) => {
      if (notification.titulo.length > characterLimit) {
        notification.titulo =
          notification.titulo.substring(0, characterLimit) + '...';
      } else {
        return notification.titulo;
      }
    });
  }

  clickBell() {
    this.buttonClicked = !this.buttonClicked;
  }

  get profissionalMatriculaId() {
    return this.storageService.getProfissionalMatriculaId();
  }

  get isMultipleMatriculas(): boolean {
    const matriculas = this.storageService.getFromLocalStorage('profissionalMatriculas', true);
    return matriculas && matriculas.length > 1;
  }

  getCurrentMatricula() {
    this.usuarioMatricula = this.usuarioSession?.profissionalMatriculas?.find(
      (matriculas) =>
        matriculas.profissionalMatriculaId === this.profissionalMatriculaId
    )?.matricula;
  }
}
