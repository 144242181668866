import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class ArquivoService {
  private controller = 'arquivo';
  public baseUrl = environment.apiUrl;
  public namespaceProject = environment.namespaceProject;

  constructor(
    private httpService: HttpClient,
    private storageService: StorageService
  ) {}

  headerFileOptions(): any {
    // tratar caso tenha o token
    const TOKEN = this.storageService.getFromLocalStorage('token')
      ? 'Bearer ' + this.storageService.getFromLocalStorage('token')
      : '';

    return {
      headers: new HttpHeaders({
        Authorization: TOKEN,
      }),
      responseType: 'blob',
      observe: 'response',
    };
  }

  upload(endpoint: string, file: File, formData = new FormData()): Observable<any> {
    formData.append('file', file);

    const TOKEN = this.storageService.getFromLocalStorage('token')
      ? 'Bearer ' + this.storageService.getFromLocalStorage('token')
      : '';

    return this.httpService
      .post(`${this.baseUrl}${endpoint}`, formData, {
        headers: { Authorization: TOKEN },
        reportProgress: true,
      })
      .pipe(
        timeout(45000),
        catchError((error) => {
          return throwError(() => error);
        })
      );
  }

  download(id: string): Observable<any> {
    return this.httpService
      .get(`${this.baseUrl}${this.controller}/${id}`, this.headerFileOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

  downloadRelatorioEmailComPendencias(params?: URLSearchParams): Observable<any> {
    const filters = params ? `?${params.toString()}` : '';
    return this.httpService.get(`${this.baseUrl}relatorio/email-profissional-com-pendencias${filters}`,
      this.headerFileOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

  downloadCustomUrl(customUrl: string, params?: URLSearchParams): Observable<any> {
    const filters = params ? `?${params.toString()}` : '';
    return this.httpService
      .get(`${this.baseUrl}${customUrl}${filters}`, this.headerFileOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

  downloadProfessor(id: string): Observable<any> {
    return this.httpService.get(`${this.baseUrl}${this.controller}/${id}/professor`,
      this.headerFileOptions()).pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }
}
