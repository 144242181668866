<ng-container [formGroup]="form">
  <div class="d-flex">
    <label class="radio-custom-container mr-2" *ngFor="let item of options">
      <input type="radio" class="mr-2" [id]="idName" [value]="item.value" [formControlName]="name"
        (change)="onChange.emit(form.get(name).value)" [checked]="item.value == form.get(name).value"  />
      <label class="d-inline mr-4" [innerHtml]="item.name"></label>
      <span class="radiomark"></span>
    </label>
  </div>
</ng-container>
