<!-- Main Sidebar Container -->
<aside class="main-sidebar sidebar-custom">
  <!-- Sidebar -->
  <div class="sidebar">
    <!-- Sidebar user panel (optional) -->
    <div class="user-panel">
      <div class="user-info">
        <div class="info">
          <img class="img-fluid mx-auto d-block" style="width: 100%; height: 100%;" src="assets/img/logo-gov-pb.png"
            alt="Logo da secretaria">
        </div>
      </div>
    </div>
    <hr>
    <div class="user-panel">
      <div class="user-info">
        <div class="info">
          <p class="name-user">{{ usuarioSession.nome }}</p>
          <span class="name-profile">{{ perfilDescription }} </span>
        </div>
      </div>
    </div>
    <hr>
    <!-- Sidebar Menu -->
    <nav class="mt-2">
      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu">
        <li class="nav-item">
          <a [routerLink]="['/']" class="nav-link">
            <em class="nav-icon fas fa-home"></em>
            <p> INÍCIO </p>
          </a>
        </li>

        <li class="nav-item has-treeview">
          <a href="javascript:void(0)" class="nav-link">
            <em class="nav-icon fas fa-sliders-h"></em>
            <p>
              CONFIGURAÇÕES
              <em class="right fas fa-angle-left"></em>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a [routerLink]="['/configuracao/perfis']" routerLinkActive="router-link-active" class="nav-link">
                <em class="nav-icon far fa-circle nav-icon"></em>
                <p>PERFIS</p>
              </a>
            </li>

            <li class="nav-item">
              <a [routerLink]="['/configuracao/usuario']" routerLinkActive="router-link-active" class="nav-link">
                <em class="nav-icon far fa-circle nav-icon"></em>
                <p>USUÁRIOS</p>
              </a>
            </li>

          </ul>
        </li>

      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
  <!-- /.sidebar -->

  <!-- Brand Logo -->
  <hr>
  <a href="javascript:void(0)" class="brand-link">
    <span class="brand-text">
      Gestão de RH
      <br />
      <span>versão 1.0.0</span>
    </span>
  </a>

</aside>
