import { Component, Input } from '@angular/core';
import { GenericChartData } from '../chart-types';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent {

  @Input() data: GenericChartData[] = [];
  @Input() view: any[] = [400, 230];
  @Input() label: string = '';

  @Input() gradient: boolean = false;
  @Input() showLegend: boolean = true;
  @Input() showLabels: boolean = false;
  @Input() isDoughnut: boolean = false;
  @Input() legendTitle: string = '';
  @Input() legendPosition: string = 'right';

  colorScheme = {
    domain: ['#6095c7', '#213242', '#C7B42C', '#AAAAAA']
  };

  constructor() {
    Object.assign(this.data);
  }

  get dataIsEmpty(): boolean {
    return !this.data?.some(x => x.value > 0) || this.data?.length === 0;
  }

  tooltipFormat (tooltip) {
    let dataTooltip = tooltip.data;

    const sum = this.data.map(x => x.value).reduce((acumulador, valorAtual) => acumulador + valorAtual);

    return dataTooltip && `${parseFloat((dataTooltip.value*100/sum).toString()).toFixed(2)}%`;

  }

}
