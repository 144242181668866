import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { PaginacaoDto } from '@core/dto/paginacao-dto';



@Component({
  selector: 'app-paginator-custom',
  templateUrl: './paginator-custom.component.html',
  styleUrls: ['./paginator-custom.component.scss']
})
export class PaginatorCustomComponent {

  @Input() paginationId = 'pagination';
  @ViewChild('p') p: any;
  @Input() public items: Array<any>;
  @Input() public itemsPerPage: number;
  @Input() public stylesClass: 'bcg-navy';
  @Input() isPaginatorSelect: boolean = false;
  @Input() pageArray: Array<number>;
  @Output() public pageChange: EventEmitter<number> = new EventEmitter();
  @Output() public qtdRegistrosChange: EventEmitter<number> = new EventEmitter();

  // teste nova paginação por demanda
  @Input() pageResponse: PaginacaoDto<any>;
  @Input() public currentPage: number;
  @Input() public pageCount: number;
  @Input() public total: number;
  public pages: Array<number> = new Array<number>();
  public count: number;

 public getTotalPerPage(page) {
    let current: number;
    let total: number;
    let itemsPerPage: number;

    if (this.pageResponse) {
      current = this.pageResponse?.currentPage ?? 0;
      total = this.pageResponse?.total ?? 0;
      itemsPerPage = this.pageResponse?.numPerPage ?? this.itemsPerPage ?? 0;
    } else if (page) {
      current = page.getCurrent();
      total = page.getTotalItems();
      itemsPerPage = this.itemsPerPage;
    }

    const start = (current - 1) * itemsPerPage + 1;
    const end = Math.min(start + itemsPerPage - 1, total);
    return end || '0';
  }

  public changeEvent(page): void {
    this.pageChange.emit(page);
  }

  public getTotalItems(): number {
    return this.pageResponse ? this.pageResponse.total : this.items.length ;
  }

  public selectQtdRegistros(event) {
    this.itemsPerPage = Number(event.target.value);
    this.qtdRegistrosChange.emit(this.itemsPerPage);
  }
}
