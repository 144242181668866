import { Injectable } from '@angular/core';
import { NotificacaoInfoDto } from '@core/dto/notificacao/notificacao-info.dto';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { Util } from 'src/app/shared/utils/util';
import { environment } from 'src/environments/environment';

import { NotificacaoService } from './notificacao.service';
import { StorageService } from './storage.service';


@Injectable({
  providedIn: 'root'
})
export class SignalRService {

  public hubConnection: HubConnection;
  public notifications = [];

  constructor(
    private notificacaoService: NotificacaoService,
    private storageService: StorageService
  ) { }

  startConnection(): void {
    const profissionalMatriculaId = this.storageService.getFromLocalStorage('profissionalMatriculaId');
    this.createConnection();
    this.hubConnection
    .start()
      .then(() => {
        this.hubConnection.invoke('Join', profissionalMatriculaId);
    })
    .catch((e) => {
      console.log('Erro na conexão com o Hub' + e.messages);
      });
  }


  createConnection() {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(environment.apiUrl + 'hub/notificacoes')
      .build();
  }

  getNotificationNotReadService() {
    const params = Util.createFilter();
    params.append('lida',  'false');
    this.notificacaoService.getByFilter(params).subscribe(
      (response) => {
        this.notifications = response;
      }
    );
  }


  registerOnServerEvents(): void {
    this.hubConnection.on('ReceiveNotification', (mensagem: NotificacaoInfoDto) => {
      this.notifications.unshift(mensagem);
    });
  }

}
