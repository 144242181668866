import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModalAvisoGeralComponent } from '@core/layouts/componentes/modal-aviso/modal-aviso-geral.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Util } from 'src/app/shared/utils/util';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private modalRef: NgbModalRef;

  constructor(private modalService: NgbModal) {}

  private openErrorModal(message: string): Observable<never> {
    this.modalRef = Util.openModal(this.modalService, ModalAvisoGeralComponent, 'md', { centered: true });
    this.modalRef.componentInstance.titleMsg = 'Atenção!';
    this.modalRef.componentInstance.message = message;
    this.modalRef.componentInstance.nameButton = 'Ok';
    return throwError(this.modalRef.result);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((httpErrorResponse) => {
        if (httpErrorResponse.error instanceof ErrorEvent) {
          // Client-side error
          if (httpErrorResponse.status !== 401) {
            return this.openErrorModal('Falha na operação.');
          }
        } else {
          // Server-side error
          const errorMessage = httpErrorResponse.error.errors || 'Falha na operação.';
          return this.openErrorModal(errorMessage);
        }
        return throwError(httpErrorResponse.error);
      })
    );
  }
}
