import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBase } from '@shared/utils/form-base';
import { ValidatorsCustom } from '@shared/utils/validators-custom';


@Component({
  selector: 'app-modal-justificativa',
  templateUrl: './modal-justificativa.component.html',
  styleUrls: ['./modal-justificativa.component.scss']
})
export class ModalJustificativaComponent extends FormBase implements OnInit {

  public nomeBotaoCancelar: string = 'Cancelar';
  public nomeBotaoOk: string = 'Confirmar';
  public mensagem: string = 'Informe abaixo a sua justificativa.';
  public label: string = 'Justificativa'
  public exibirHeader: boolean = true;
  public ehVisualizacao: boolean = false;
  @Input() justificativa: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    super(router, activatedRoute);
  }

  ngOnInit(): void {
    this.createFormGroup();

    if (this.ehVisualizacao) {
      this.form.get('justificativa').setValue(this.justificativa);
      this.form.disable();
    }
  }

  createFormGroup() {
    this.form = this.formBuilder.group({
      justificativa: ['', ValidatorsCustom.noWhitespaceValidator]
    });
  }

  public closeModal() {
    this.activeModal.close();
  }

  public emitirValorJustificativa() {
    const justificativaValue = this.form.get('justificativa').value;
    this.activeModal.close({ justificativa: justificativaValue });
  }

}
