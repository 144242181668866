import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: [ './progress-bar.component.scss' ]
})
export class ProgressBarComponent {

  @Input() quantidadeParcial: number = 0;
  @Input() quantidadeTotal: number = 0;

  get label() {
    if (this.quantidadeParcial === 0) {
      return 0 + '/' + this.quantidadeTotal;
    } else {
      return this.quantidadeParcial + '/' + this.quantidadeTotal;
    }
  }

  get isQtdParcialZero() {
    return this.quantidadeParcial === 0;
  }

}
