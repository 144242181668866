import { Component, OnInit } from '@angular/core';
import { PermissionsInitService } from '@core/services/permissions-init.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'rh-client';
  constructor(private permissionsInitService: PermissionsInitService) {}

  ngOnInit() {
    this.permissionsInitService.initializePermissions();
  }
}
