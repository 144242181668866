import { Injectable } from '@angular/core';
import { UsuarioAlterarSenhaDto } from '@core/dto/usuario/usuario-alterar-senha.dto';
import { UsuarioTelefoneUpdateDto } from '@core/dto/usuario/usuario-telefone-update.dto';

import { Guid } from 'guid-typescript';
import { map } from 'rxjs/operators';
import { PaginacaoDto } from '../dto/paginacao-dto';

import { Observable } from 'rxjs';
import { UsuarioFormDTO } from '../dto/usuario/usuario-form.dto';
import { UsuarioListDTO } from '../dto/usuario/usuario-list.dto';
import { UsuarioUpdateDto } from '../dto/usuario/usuario-update.dto';
import { URL_CONFIG } from '../utils/url-configs';
import { ApiService } from './api.service';


@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(private apiService: ApiService) { }

  public getByFilter(params?: URLSearchParams) {
    const filters = params ? `?${params.toString()}` : '';
    return this.apiService.get(`${URL_CONFIG.url_usuario}${filters}`)
      .pipe(map(response => new PaginacaoDto<UsuarioListDTO>(response.body.data)));
  }

  public getById(id: string) {
    return this.apiService.get(`${URL_CONFIG.url_usuario}/${id}`).pipe(map(response => new UsuarioFormDTO(response.body.data)));
  }

  public add(obj: UsuarioFormDTO) {
    return this.apiService.post(`${URL_CONFIG.url_usuario}`, obj);
  }

  public update(obj: UsuarioUpdateDto) {
    return this.apiService.put(`${URL_CONFIG.url_usuario}/${obj.id}`, obj);
  }

  public delete(id: string | Guid) {
    return this.apiService.delete(`${URL_CONFIG.url_usuario}/${id}`);
  }

  public inactivate(obj: UsuarioListDTO) {
    return this.apiService.patch(`${URL_CONFIG.url_usuario}/${obj.id}`, !obj.ativo);
  }

  updateTelefone(obj: UsuarioTelefoneUpdateDto) {
    return this.apiService.put(`${URL_CONFIG.url_usuario}/telefone`, obj);
  }

  public alterarSenha(obj: UsuarioAlterarSenhaDto) {
    return this.apiService.post(`${URL_CONFIG.url_usuario}/alterar-senha`, obj);
  }

  public verificarSenhaUserLogado(params?: any): Observable<any> {
    const filters = params ? `?${params.toString()}` : '';
    return this.apiService.get(`${URL_CONFIG.url_usuario}/verificar-senha${filters}`)
    .pipe(map(response => response.body.data));
  }
}
