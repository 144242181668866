import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { FormBase } from '../../utils/form-base';
import { ActivatedRoute, Router } from '@angular/router';

interface Opcao {
  id: number;
  resposta: string;
  valor: number;
}

interface Questao {
  id?: string;
  perguntaId?: string;
  pergunta: string;
  opcoes: Opcao[];
  resposta?: number;
  peso: number;
}

@Component({
  selector: 'app-questionnaire',
  templateUrl: './questionnaire.component.html',
  styleUrls: ['./questionnaire.component.scss']
})
export class QuestionnaireComponent extends FormBase implements OnInit {
  @Input() parentForm: FormGroup;
  @Input() isEditable = true;
  @Output() sumValue = new EventEmitter();
  @Output() onChangeResponse = new EventEmitter();

  @Input() questions: Questao[];
  questionnaireForm: FormGroup;


  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    super(router, activatedRoute);
  }

  ngOnInit() {
    this.createFormGroup();
  }

  createFormGroup() {
    this.questionnaireForm = this.formBuilder.group({
      questionsArray: this.formBuilder.array(
        []
      )
    });

    this.initForm();
    this.parentForm?.setControl('questionario', this.questionnaireForm);
  }


  initForm() {
    const questionArray = this.questionnaireForm.get('questionsArray') as FormArray;

    this.questions.forEach((question) => {
      const optionArray = this.formBuilder.array(
        question.opcoes.map((option) => {
          return this.formBuilder.group({
            perguntaId: new FormControl(question.perguntaId || question.id),
            selected: new FormControl(question.resposta === option.valor),
            value: new FormControl(option.valor)
          });
        })
      );

      optionArray.setValidators(this.atLeastOneSelected());

      questionArray.push(
        this.formBuilder.group({
          opcoes: optionArray,
          pontuacao: new FormControl(question.resposta),
          peso: new FormControl(5),
          totalPontos: new FormControl(question.resposta * 5)
        })
      );

    });

    this.sum();
  }


  atLeastOneSelected(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const questions = control.value;
      const hasAtLeastOneSelected = questions?.some((option) => option.selected);

      return hasAtLeastOneSelected ? null : { noOptionSelected: true };
    };
  }


  isChecked(option) {
    return option.get('selected').value;
  }


  markOption(question, option) {
    if (!this.isEditable) return;
    this.uncheckAll(question);
    option.get('selected').patchValue(!this.isChecked(option));
    this.sum();
    this.respostas();
  }

  uncheckAll(question) {
    question.controls.forEach((option: FormControl) => {
      option.get('selected').patchValue(false);
    });
  }

  questiondescricao(index: number): string {
    return this.questions[index].pergunta;
  }

  questionOptiondescricao(questionIndex: number, optionIndex: number): string {
    return this.questions[questionIndex].opcoes[optionIndex].resposta;
  }

  sum() {
    const selected = [].concat(...this.questionnaireForm.get('questionsArray').value.map(x => x.opcoes.filter(y => y.selected)));
    const sum = selected?.map(x => x.value)?.reduce((a, b) => a + b);
    this.questionnaireForm.get('questionsArray').value.forEach((question, index) => {
      const peso = question.peso;
      const pontuacao = question.pontuacao;
      question.totalPontos = pontuacao * peso;
    });
    this.sumValue.emit(sum);
  }

  respostas() {
    const selected = [].concat(...this.questionnaireForm.get('questionsArray').value.map(x => x.opcoes.filter(y => y.selected)));
    const respostas = selected.map(x => {
      return {
        perguntaId: x.perguntaId,
        resposta: x.value
      };
    });
    this.onChangeResponse.emit(respostas);
  }

  get isRouteTermo() {
    return this.router.url.includes('termo');
  }
}
