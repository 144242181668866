import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ArquivoCreateDto } from '@core/dto/arquivo/arquivo-create-dto';
import { Arquivodto } from '@core/dto/arquivo/arquivo.dto';
import { ModalAvisoGeralComponent } from '@core/layouts/componentes/modal-aviso/modal-aviso-geral.component';
import { ArquivoService } from '@core/services/arquivo.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBase } from '../../utils/form-base';
import { Util } from '../../utils/util';

@Component({
  selector: 'app-upload-one-file',
  templateUrl: './upload-one-file.component.html'
})
export class UploadOneFileComponent extends FormBase {

  @Input() editable: boolean = true;
  @Input() accept = '.pdf';
  @Input() form: FormGroup = new FormGroup({});
  @Input() name = '';
  @Input() canDownload = true;
  @Input() isEditable: boolean;
  @Input() nameButton: string;
  @Input() legend: string;
  @ViewChild('file') inputRef!: any;
  @Input() maxSize = 10; // In Mb
  @Input() acceptedTypes = [ 'pdf', 'jpg', 'png', 'jpeg' ];
  @Output() isEditChanged = new EventEmitter();
  @Output() update = new EventEmitter();
  @Input() classButton: string;
  @Input() messageFileSize: string = '';
  @Input() nameButtonAlert: string = 'OK';
  @Input() isProfessor = false;
  public file;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private arquivoService: ArquivoService) {
    super(router, activatedRoute);
  }

  get formControl() {
    return this.form?.get(this.name);
  }

  async getBase64FromFile(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve((reader.result as string)?.split(';base64,').pop() ?? '');
      reader.onerror = (error) => reject(error);
    });
  }

  public async onSelectFile(event: Event): Promise<void> {
    if (!this.editable) {
      return;
    }
    this.file = (event.target as HTMLInputElement).files[ 0 ];
    this.inputRef.nativeElement.value = '';
    this.form.controls[ this.name ].setValue(this.file);
    if (this.file) {
      if (!this.isValidFile(this.file, this.messageFileSize)) {
        this.clear();
      } else {
        const base64 = await this.getBase64FromFile(this.file);
        const arquivoDto = new ArquivoCreateDto({
          id: this.formControl?.value?.id,
          base64,
          extensao: this.file.type.split('/')[ 1 ],
          acao: this.formControl?.value?.id ? 'EDIT' : 'CAD',
          nome: this.file.name.split('.')[ 0 ],
        });
        this.formControl?.setValue(arquivoDto);
      }
    }
  }

  isValidFile(file: File, messageFileSize?: string): boolean {

    let error = '';
    // Validação do tamanho do arquivo
    if (file.size > this.maxSize * 1024 * 1024) {
      if (messageFileSize) {
        error = messageFileSize;
      } else {
        error = `O tamanho do arquivo não pode ser maior que ${this.maxSize}MB.`;
      }
    }

    // Validação do tipo do arquivo
    const extension = `${file.name}`.split('.').pop()?.toLocaleLowerCase();
    if (extension && !this.acceptedTypes.includes(extension)) {
      error = `Não é possível adicionar documentos em outros formatos. Por favor, verifique se o documento que deseja enviar está nos formatos permitidos (${this.getTxtExtensions()}).`;
    }

    if (error) {
      const modalRef = Util.openModal(
        this.modalService,
        ModalAvisoGeralComponent, 'lg', { centered: true });
      modalRef.componentInstance.message = error;
      modalRef.componentInstance.isOneButton = true;
      modalRef.componentInstance.nameButton = 'Ok';
      return false;
    } else {
      return true;
    }
  }

  private getTxtExtensions() {
    let txt = '';
    this.acceptedTypes.forEach((type, index) => {
      txt = txt.concat(`.${type.toUpperCase()}`);
      if (this.acceptedTypes.length -2 === index) {
        txt = txt.concat(' e ');
      }else if (this.acceptedTypes.length -3 >= index) {
        txt = txt.concat(', ');
      }
    })
    return txt;
  }

  public clear(): void {
    this.isEditable = false;
    this.formControl.setValue(null);
    this.file = null;
    this.inputRef.nativeElement.value = '';
    this.viewAnexo();
  }

  public downloadFile(base64: string, fileName: string): void {
    const downlodedFile = Util.convertBase64ToBlobData(base64, this.file?.type || 'application/pdf');
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = window.URL.createObjectURL(downlodedFile);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }

  public  downloadFromBack(arquivo: any): void {
    this.arquivoService.download(arquivo.id.toString()).subscribe(async res => {
      const base64 = await this.getBase64FromFile(res.body);
      this.downloadFile(base64, arquivo.nomeOriginal || arquivo.nome);
    }, (err) => { });
  }

  public downloadProfessorFromBack(arquivo: Arquivodto): void {
    this.arquivoService.downloadProfessor(arquivo.id.toString()).subscribe(async res => {
      const base64 = await this.getBase64FromFile(res.body);
      this.downloadFile(base64, arquivo.nome);
    }, (err) => { });
  }

  public download(): void {
    const arquivo = this.form.controls[ this.name ].value;
    if (arquivo.nomeOriginal && this.isProfessor) {
      this.downloadProfessorFromBack(arquivo);
      return;
    }
    if (arquivo.id) {
      this.downloadFromBack(arquivo);
      return;
    }
    this.downloadFile(arquivo.base64, arquivo.nome);
  }

  public viewAnexo(): boolean {
    if (this.file || this.form.get(this.name)?.value) {
      return true;
    }
    return false;
  }

}

