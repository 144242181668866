import { Routes } from '@angular/router';
import { SelecionarMatriculaComponent } from '@modules/auth/pages/selecionar-matricula/selecionar-matricula.component';
import { LayoutAuthComponent } from './core/layouts/layout-auth/layout-auth.component';
import { LayoutNoAuthComponent } from './core/layouts/layout-no-auth/layout-no-auth.component';

export const APP_ROUTES: Routes = [
  {
    path: 'auth',
    component: LayoutNoAuthComponent,
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
    data: { breadcrumb: null }
  },
  {
    path: 'selecionar-matricula',
    component: SelecionarMatriculaComponent
  },
  {
    path: '',
    component: LayoutAuthComponent,
    data: { breadcrumb: null },
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./modules/home/home.module').then((m) => m.HomeModule),
        data: { breadcrumb: null }
      },
      {
        path: 'avaliacao-desempenho',
        loadChildren: () =>
          import('./modules/avaliacao-desempenho/avaliacao-desempenho.module').then(
            (m) => m.AvaliacaoDesempenhoModule),
        data: { breadcrumb: 'Avaliação de Desempenho' }
      },
      {
        path: 'configuracao',
        loadChildren: () =>
          import('./modules/configuracao/configuracao.module').then(
            (m) => m.ConfiguracaoModule
          ),
        data: { breadcrumb: 'Configurações' }
      },
      {
        path: 'area-usuario',
        loadChildren: () =>
          import('./modules/area-usuario/area-usuario.module').then(
            (m) => m.AreaUsuarioModule
          ),
        data: { breadcrumb: 'Área do Usuário' }
      },
      {
        path: 'ferias',
        loadChildren: () =>
          import('./modules/ferias/ferias.module').then(
            (m) => m.FeriasModule
          ),
        data: { breadcrumb: 'Férias' }
      },
      {
        path: 'profissionais',
        loadChildren: () =>
          import('./modules/profissionais/profissionais.module').then(
            (m) => m.ProfissionaisModule
          ),
        data: { breadcrumb: 'Profissionais' }
      },
      {
        path: 'quadro-funcional',
        loadChildren: () =>
          import('./modules/quadro-funcional/quadro-funcional.module').then(
            (m) => m.QuadroFuncionalModule
          ),
        data: { breadcrumb: 'Quadro Funcional'}
      },
      {
        path: 'afastamentos',
        loadChildren: () =>
          import('./modules/afastamentos/afastamentos.module').then(
            (m) => m.AfastamentosModule
          ),
        data: { breadcrumb: 'Afastamentos' }
      },
      {
        path: 'ficha-financeira',
        loadChildren: () =>
          import('./modules/ficha-financeira/ficha-financeira.module').then(
            (m) => m.FichaFinanceiraModule),
        data: { breadcrumb: 'Ficha Financeira' }
      },
      {
        path: 'ficha-financeira-servidor',
        loadChildren: () =>
          import('./modules/ficha-financeira-servidor/ficha-financeira-servidor.module').then(
            (m) => m.FichaFinanceiraServidorModule),
        data: { breadcrumb: 'Ficha Financeira' }
      },
    ]
  }
];
