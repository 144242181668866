<div class="card mb-0">
    <div class="card-header">
        <h5 class="aviso">{{tituloModal}}</h5>
    </div>
    <div class="card-body">
        <form [formGroup]="form" (keydown.enter)="$event.preventDefault()" id="form-anexo">
            <div class="row flex align-items-center justify-content-center">
                <div class="col-12 col-md-7">
                    <app-upload-one-file accept=".jpg, .jpeg, .png, .pdf " [form]="form"
                        [classButton]="'btn btn-outline-primary btn-sm rounded-pill col-12'" name="anexo"
                        [editable]="true" [isEditable]="true" [nameButton]="'Escolha um arquivo para fazer upload'"
                        [legend]="'Tipos de arquivos: .jpg, .jpeg, .png e .pdf.<br>Tamanho máximo: 10 MB'">
                        <small class="form-text text-red" *ngIf="validateMessage.messageDisplay.anexo"
                            [innerText]="validateMessage.messageDisplay.anexo">
                        </small>
                    </app-upload-one-file>
                </div>
            </div>
        </form>
    </div>
    <div class="d-flex justify-content-center">
        <button id="btn-cancelar" type="submit" title="Cancelar" (click)="closeModal()"
            class="btn btn-outline-primary rounded-pill mb-4">
            Cancelar
        </button>
        <button id="btn-salvar" type="submit" title="Salvar" (click)="submit()"
            class="btn btn-primary rounded-pill mb-4 ml-2">
            Salvar
        </button>
    </div>
</div>