import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginacaoDto } from '@core/dto/paginacao-dto';
import { FormBase } from '@shared/utils/form-base';
import { SweetalertCustom } from '@shared/utils/sweetalert-custom';
import { GenericListOptions } from '../generic-types';
import { GenericListColumn } from './../generic-types';
import { CustomColumnsDirective } from './directives/custom-columns.directive';


@Component({
  selector: 'app-generic-list',
  templateUrl: './generic-list.component.html',
  styleUrls: ['./generic-list.component.scss']
})
export class GenericListComponent extends FormBase {

  @ContentChild(CustomColumnsDirective, { static: true, read: TemplateRef }) customColumns;

  @Input() page = new PaginacaoDto<any>();
  @Input() canSubmit: boolean = true;
  @Input() options: GenericListOptions<any>;
  @Input() items: any[] = [];
  @Input() registerButton: boolean = true;
  @Input() showFilter: boolean = true;
  @Input() isCrud: boolean = false;
  @Input() paginatorCustom: boolean = false;

  @Input() customEditPath: string;
  @Input() customRegisterPath: string;
  @Input() emptyMessage: string = 'Nenhum registro foi encontrado';

  @Output() onChangePage = new EventEmitter<number>();
  @Output() onOrder = new EventEmitter<string>();
  @Output() onFilter = new EventEmitter<boolean>();
  @Output() onInactive = new EventEmitter<string>();
  @Output() onClearFilter = new EventEmitter<boolean>();
  @Output() onDelete = new EventEmitter<string>();
  @Output() onClick = new EventEmitter<string>();
  @Output() onCheck = new EventEmitter<any>();

  @Input() acao: string = 'Ações';

  constructor(public router: Router, public activedRoute: ActivatedRoute) {
    super(router, activedRoute);
  }

  get table() {
    return this.options?.tableConfig;
  }

  get showActions() {
    return Object.values(this.table.actions).some(can => can);
  }

  get pathRegister() {
    return this.router.url + '/cadastrar/';
  }

  get pathEdit() {
    return this.customEditPath || this.router.url + '/editar/';
  }

  get pathView() {
    return this.customEditPath || this.router.url + '/visualizar/';
  }

  get totalColspan() {
    return this.table?.columns.length + (this.showActions ? 1 : 0);
  }

  getValueColumn(item: any, column: GenericListColumn<any>) {
    const value = item[column.attribute];
    if (column.formatter)
      return column.formatter(item);
    if (typeof value === 'boolean')
      return item[column.attribute] ? 'Sim' : 'Não';
    return item[column.attribute];

  }

  submit() {
    this.onFilter.emit();
  }

  clearFilter() {
    this.onClearFilter.emit(true);
  }

  inactive(item: any) {
    this.onInactive.emit(item);
  }

  changePage(page: any) {
    this.onChangePage.emit(page);
  }

  orderColumn(column: any) {
    this.onOrder.emit(column);
  }

  view(item: any) {
    this.router.navigateByUrl(this.pathView + item.id);
  }

  edit(item: any) {
    this.router.navigateByUrl(this.pathEdit + item.id);
  }

  delete(item: any) {
    this.onDelete.emit(item);
  }

  lineClicked(item: any) {
    this.onClick.emit(item);
  }

  check(item: any, checked: any) {
    this.onCheck.emit({item, checked});
  }

  public exibirTabelasRelacionadas(item: any) {
    SweetalertCustom.ShowAlertConfirmExclusaoByRelacionamento(
      item.tabelasRelacionadas
    );
  }

  public definirCorIcone(item: any) {
    return item.ativo ? 'fa-toggle-on' : 'fa-toggle-on fa-toggle-off';
  }
}
