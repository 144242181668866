<div class="card">
  <div class="card-body">
    <div  class="row">
      <div #ref class="col-6 d-flex flex-column justify-content-center">
        <ng-content content class="d-flex"></ng-content>
      </div>
      <div [ngClass]="ref.children.length == 0 ? 'col-12' : 'col-6'">
        <div>
          <h5><strong> {{ label }} </strong></h5>
        </div>
        <div *ngIf="!dataIsEmpty" #chart>
          <ngx-charts-pie-chart
            [view]="view"
            [results]="data"
            [gradient]="gradient"
            [scheme]="colorScheme"
            [legend]="showLegend"
            [legendPosition]="legendPosition"
            [legendTitle]="legendTitle"
            [labels]="showLabels"
            [tooltipText]="tooltipFormat"
            [doughnut]="isDoughnut"
            >
          </ngx-charts-pie-chart>
        </div>

        <div class="row " style="height: 240px;" *ngIf="dataIsEmpty">
          <p>
            Não há dados a serem exibidos.
          </p>
        </div>
      </div>
    </div>

  </div>
</div>
