<div class="info-bar">
  <div class="container">
    <div class="row justify-content-{{ usuarioSession ? 'between' : 'end' }} align-items-center m-0">
      <div *ngIf="usuarioSession" class="text-light d-flex h-100 align-items-center"> <strong>{{ usuarioSession.nome }} {{ usuarioSession.sobreNome }} -  Matrícula: {{ usuarioMatricula }}</strong></div>
      <ul class="nav-info" id="nav-info">
        <li>
          <div class="dropdown mr-3">
            <button id="btn-ver-notificacoes" class="btn buttonBell dropdown-toggle btn-sm" data-display="static" data-toggle="dropdown" (click)="clickBell()" [ngClass]="{ 'button-active' : buttonClicked }">
              <em class="fa fa-bell iconBell"></em>
              <span class="badge badge-pill badge-danger position-absolute top-0 end-0" *ngIf="countNotifications > 0">
                <div *ngIf="countNotifications <= 99">{{ countNotifications }}</div>
                <div *ngIf="countNotifications > 99">99+</div>
              </span>
            </button>
            <div class="dropdown-menu dropdown-menu-lg-right">
              <div *ngIf="countNotifications > 0">
                <button id="btn-redirect-to-home-with-focus" class="btn button-item btn-light mr-2 ml-2 mb-2 btn-sm align-content-lg-start" *ngFor="let item of signalRService.notifications | slice:0:4" (click)="redirectToHomeWithFocus(item)">
                  <p class="notification text-left">{{ item.titulo }} </p>
                </button>
              </div>
              <p class="text-center notificationsEmpty" *ngIf="countNotifications == 0">Não há notificações não lidas :</p>
              <div>
                <button id="btn-button-vertodas" class="btn btn-light button-ver-todas btn-sm float-left ml-2 mt-2" (click)="redirectToHome()">Ver Todas</button>
                <button id="btn-redirect-home" class="btn btn-link btn-sm float-right mr-2 mt-2" *ngIf="countNotifications > 4" (click)="redirectToHome()">+{{ countMoreNotifications }} notificações não lidas</button>
              </div>
            </div>
          </div>
        </li>
        <li *ngFor="let menu of menusInfoBar">
          <a id="menu-info-bar"
          class="nav-item mr-1"
          [title]="menu.label"
          [routerLink]="menu.url"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: menu.exact }">
          {{ menu.label }}
          </a>
        </li>
        <li>
          <a id="trocar-matricula" [routerLink]="['/selecionar-matricula']" class="nav-item active mr-1" title="Trocar Matrícula" *ngIf="isMultipleMatriculas"> Trocar Matrícula </a>
        </li>
        <li>
          <a id="logout" [routerLink]="['/auth/logout']" class="nav-item active" title="Sair"> Sair </a>
        </li>
      </ul>
    </div>
  </div>
</div>
<nav>
  <div class="container h-100">
    <div class="row h-100">
      <a id="logoClick" routerLink="/" class="col-4 logo-container">
        <img src="assets/img/brasao-atualizado.png" alt="" srcset="" class="logo" />
      </a>

      <!-- Menu -->
      <ul class="nav-menu">
        <li *ngFor="let menu of menus">
          <a
            id="nav-menu"
            *ngIf="shouldShowNavbarItem(menu) || shouldShowNavbarItemPermissoes(menu)"
            [title]="menu.label"
            class="nav-item"
            [routerLink]="menu.url"
            routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: menu.exact }"
          >
            {{ menu.label }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</nav>
