import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AutocompleteService } from '@core/services/autocomplete.service';
import { Util } from '@shared/utils/util';
import { TreeNode } from 'primeng';

@Component({
  selector: 'app-tree-selection',
  templateUrl: './tree-selection.component.html',
  styleUrls: ['./tree-selection.component.scss']
})
export class TreeSelectionComponent {
  itensArvore: any[];
  @Input() label = 'Selecione';
  isDropdownOpen = false;
  @Input() selectedModulos: any;
  @Input() isEdicao: boolean;
  @Input() disabledInput = false;
  @Input() selectedItens: TreeNode[];
  @Output() selectedItensChange = new EventEmitter<any>();
  @Output() onRemoveAllSelected = new EventEmitter<any>();

  constructor(
    private autoCompleteService: AutocompleteService
  ) {}


  getModulosParametro(){
    const parametros: URLSearchParams = Util.createFilter();
    if (this.selectedModulos.length > 0) {
      this.selectedModulos.forEach(modulo => {
        parametros.append('moduloIds', modulo.id);
      });
    }
    return parametros;
  }

  getValoresIniciaisComponente(){
    const parametros = this.getModulosParametro();
    this.autoCompleteService.getPermissoesForTree(parametros).subscribe((permissoesBackend) => {
      const permissoes = this.transformarPermissoesBackendParaArvore(permissoesBackend);
      this.itensArvore = this.formatarPermissoesParaComponenteArvore(permissoes);
      if(!this.isEdicao){
       this.resetarArvore();
      }else{
        this.selecionarItemsEdicao();
      }
    });
  }

  resetarArvore(){
    this.selectedItens = [];
    this.selectedItensChange.emit(this.selectedItens);
    this.label = `Selecione`;
  }

  selecionarItemsEdicao(){
    const idsSelecionados = this.extrairIdsSelecionadosNaEdicao(this.selectedItens);
    this.selectedItens = this.filtrarIdsExistentesSelecionadosNaEdicao(this.itensArvore, idsSelecionados);
    this.selectedItensChange.emit(this.selectedItens);
  }

  selecionarItemsNaArvore(itens: any[]) {
    this.selectedItens = [];
    this.marcarItensComoSelecionados(itens);
    this.selectedItensChange.emit(this.selectedItens);
    this.label = `${this.selectedItens?.length} selecionados`;
  }

  marcarItensComoSelecionados(itens: any[]) {
    itens.forEach(item => {
      this.selectedItens.push(item);
      if (item.children) {
        this.marcarItensComoSelecionados(item.children);
      }
    });
  }

  toggleDropdown() {
    if(!this.disabledInput){
      if(!this.isDropdownOpen){
        this.getValoresIniciaisComponente();
      }
      this.isDropdownOpen = !this.isDropdownOpen;
      const dropdownMenu = document.querySelector('.dropdown-tree');
      dropdownMenu.classList.toggle('show');
    }
  }

  nodeSelect(event) {
    this.selectedItensChange.emit(this.selectedItens);
    this.label = `${this.selectedItens?.length} selecionados`;
  }

  nodeUnselect(event) {
    if(this.isEdicao){
      if(this.selectedItens.length === 0){
        this.onRemoveAllSelected.emit(true);
      }
    }
    this.selectedItensChange.emit(this.selectedItens);
    this.label = `${this.selectedItens?.length} selecionados`;
  }

  /*
    --- Funções de manipular os dados da arvore---
  */

  extrairIdsSelecionadosNaEdicao(itens: any[]): string[] {
    const ids: string[] = [];
    itens.forEach(item => {
      if (item.id) {
        ids.push(item.id);
      }
    });
    return this.removerDuplicacoes(ids);
  }

  removerDuplicacoes(ids: string[]) {
    return Array.from(new Set(ids));
  }

  filtrarIdsExistentesSelecionadosNaEdicao(itensArvore: any[], idsSelecionados: string[]): any[] {
    const itensExistentes: any[] = [];
    itensArvore.forEach(item => {
      if (idsSelecionados.includes(item.id)) {
        itensExistentes.push(item);
      }
      if (item.children) {
        itensExistentes.push(...this.filtrarIdsExistentesSelecionadosNaEdicao(item.children, idsSelecionados));
      }
    });
    return itensExistentes;
  }

  /*
    Adiciona os childrens e parents no formato que o componente espera
  */
    formatarPermissoesParaComponenteArvore(nodes: TreeNode[], parent: TreeNode | null = null): TreeNode[] {
      return nodes.map(node => {
        const newNode: TreeNode = { ...node, parent };

        if (node.children && node.children.length > 0) {
          newNode.children = this.formatarPermissoesParaComponenteArvore(node.children, newNode);
        }

        return newNode;
      });
    }

  /**
   * Formata o objeto que vem da ARVORE para o formato da LISTAGEM de permissões
   */
  public transformarEmObjetoPermissoes(data: TreeNode[]): any[] {
    if (data?.length === 0) return [];

    const permissoesAcao = this.filtrarSomenteAcoes(data);
    const permissoesOrganizadas =  this.organizarPermissoes(permissoesAcao)
    const permissoesAgrupadas = this.agruparPermissoesPorModulo(permissoesOrganizadas)

    /*Transformar agrupamento em um array */
    const permissoes = Object.values(permissoesAgrupadas).map((moduloObj:any) => {
      return {
        modulo: moduloObj.modulo,
        cruds: Object.values(moduloObj.cruds)
      };
    });
    return permissoes;
  }

  private filtrarSomenteAcoes(data:any){
    return data?.filter(item => {
      if(item?.parent?.parent) return item;
    });
  }

  private organizarPermissoes(data:any){
    return data?.map(item => {
      return {
        id: item?.id,
        ativo: item.ativo,
        acao: item?.label,
        crud: item?.parent?.label,
        modulo: item?.parent?.parent?.label
      };

    });
  }

  private agruparPermissoesPorModulo(data: any){
    return data?.reduce((acc, item) => {
      const chaveModulo = item.modulo;
      if (!acc[chaveModulo]) {
        acc[chaveModulo] = { id:item.modulo ,modulo: item.modulo, cruds: {} };
      }

      const chaveCrud = item.crud;
      if (!acc[chaveModulo].cruds[chaveCrud]) {
        acc[chaveModulo].cruds[chaveCrud] = { id:item.crud, crud: item.crud, acoes: [] };
      }

      acc[chaveModulo].cruds[chaveCrud].acoes.push({id:item.id , acao: item.acao, ativo: item.ativo});
      return acc;
    }, {});
  }

  /**
   * Formata o objeto que vem do back para o formato da ARVORE
   */
  transformarPermissoesBackendParaArvore(permissoesBackend: any[]): any[] {
    return permissoesBackend.map(modulo => {
      const crudsMap = new Map();
      modulo.permissoes.forEach(permissao => {
        const [crud, acoes] = permissao.nome.split(' - ');
        if (!crudsMap.has(crud)) {
          crudsMap.set(crud, {
            id: crud,
            label: crud,
            children: []
          });
        }
        crudsMap.get(crud).children.push({
          id: permissao.id,
          label: acoes,
          ativo: true,
        });
      });

      return {
        id: modulo.modulo,
        label: modulo.modulo,
        children: Array.from(crudsMap.values())
      };
    });
  }

  /*
    Formata o objeto que vem do back para o formato que a LISTAGEM de permissões espera
  */
    public transformarPermissoesBackendParaListagem(permissoesBackend: any[]): any[] {
      return permissoesBackend.map(modulo => {
        const permissoesOrganizadas = modulo.permissoes.map(permissao => {
          const [crud, acoes] = permissao.nome.split(' - ');
          return {
            id: permissao.id,
            acao: acoes,
            crud: crud,
            modulo: modulo.modulo,
            ativo: modulo.ativo
          };
        });

        const permissoesAgrupadas = this.agruparPermissoesPorModulo(permissoesOrganizadas);

        return {
          modulo: modulo.modulo,
          cruds: Object.values(permissoesAgrupadas[modulo.modulo].cruds)
        };
      });
    }

  /*
    Formata o objeto que vem da LISTAGEM para que o BACK espera no Salvar/Editar
  */
    public transformarPermissoesListagemParaSalvar(permissoesList: any[], tipo:string = "CAD"): any[] {
      const permissoes = [];
      permissoesList.forEach(modulo => {
        modulo.cruds.forEach(crud => {
          crud.acoes.forEach(acao => {
            permissoes.push({
              id: acao.id,
              acao: tipo,
              ativo: acao.ativo,
            });
          });
        });
      });

      return permissoes;
    }

    /*
      Transforma os items da listagem para o formato da arvore no editar
    */
    transformarItensDaListagemParaArvore(permissao: any): any {
      return [{
        id: permissao.modulo,
        label: permissao.modulo,
        children: permissao?.cruds.map(crud => ({
          id: crud.crud,
          label: crud.crud,
          children: crud.acoes.map(acao => ({
            id: acao.id,
            ativo: acao.ativo,
            label: acao.acao
          }))
        }))
      }];
    }

    /*
      Verifica e Filtra se já existe alguma permissão com o mesmo ID na listagem de permissões
    */
    public verificarIdsRepetidos(novasPermissoes: any[], listaPermissoesAtual: any[]): any[] {
      const idsNovasPermissoes = new Set<string>();
      novasPermissoes.forEach(modulo => {
        modulo.cruds.forEach(crud => {
          crud.acoes.forEach(acao => {
            idsNovasPermissoes.add(acao.id);
          });
        });
      });

      const acoesRepetidas = [];
      listaPermissoesAtual.forEach(modulo => {
        modulo.cruds.forEach(crud => {
          const acoes = [];
          crud.acoes.forEach(acao => {
            if (idsNovasPermissoes.has(acao.id)) {
              acoes.push({ nome: acao.acao });
            }
          });
          if (acoes.length > 0) {
            acoesRepetidas.push({
              crud: crud.crud,
              acoes: acoes
            });
          }
        });
      });
      return acoesRepetidas;
    }

    /*
      Adiciona as novas Permissoes na Listagem de Permissoes
    */
    public adicionarPermissoesExistentes(permissoesExistentes: any[], novasPermissoes: any[]): any[] {
      novasPermissoes.forEach(novoModulo => {
        const moduloExistente = permissoesExistentes.find(modulo => modulo.modulo === novoModulo.modulo);

        if (moduloExistente) {
          novoModulo.cruds.forEach(novoCrud => {
            const crudExistente = moduloExistente.cruds.find(crud => crud.crud === novoCrud.crud);

            if (crudExistente) {
              novoCrud.acoes.forEach(novaAcao => {
                if (!crudExistente.acoes.some(acao => acao.id === novaAcao.id)) {
                  crudExistente.acoes.push(novaAcao);
                }
              });
            } else {
              moduloExistente.cruds.push(novoCrud);
            }
          });
        } else {
          permissoesExistentes.push(novoModulo);
        }
      });
      this.label = `Selecione`;
      return permissoesExistentes;
    }

    /*
      Filtra somente os Ids as permissoes recebidas do back para utilizar no editar
    */
      filtrarIdsDasPermissoesParaEditar(permissoes: any[]): { id: string, ativo: boolean }[] {
        const idsNovasPermissoes: { id: string, ativo: boolean }[] = [];
        permissoes.forEach(modulo => {
          modulo.cruds.forEach(crud => {
            crud.acoes.forEach(acao => {
              idsNovasPermissoes.push({ id: acao.id, ativo: acao.ativo });
            });
          });
        });
        return idsNovasPermissoes;
      }

    /*
      Faz o mapeamento das permissoes de acordo com o tipo da acao que o back espera para EXCLUIR, ou ADICIONAR a permissao
    */
    formatarPermissoesParaEditarComAcoes(permissoesEdit: any[], idsForEdit: { id: string, ativo: boolean }[]): any[] {
      const permissoesMap = new Map<string, any>();

      permissoesEdit.forEach(permissao => {
        permissoesMap.set(permissao.id, permissao);
      });

      idsForEdit.forEach(({ id, ativo }) => {
        if (permissoesMap.has(id)) {
          const permissaoExistente = permissoesMap.get(id);
          if (permissaoExistente.ativo !== ativo) {
            permissoesMap.set(id, { ...permissaoExistente, acao: "EDIT", ativo: permissaoExistente.ativo });
          } else {
            permissoesMap.delete(id);
          }
        } else {
          permissoesMap.set(id, { id, acao: "EXC", ativo });
        }
      });

      return Array.from(permissoesMap.values());
    }
}


