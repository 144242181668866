import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-aviso-padrao',
  templateUrl: './modal-aviso-padrao.component.html',
  styleUrls: ['./modal-aviso-padrao.component.scss']
})
export class ModalAvisoPadraoComponent {

  @Input() message;
  @Input() nameButton = 'Ok';

  constructor(
    public activeModal: NgbActiveModal,
    public router: Router,
  ) { }

  public closeModal() {
    this.activeModal.close();
  }
}
