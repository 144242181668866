import { Component, Input } from '@angular/core';
import { Routes } from '@angular/router';

@Component({
  selector: 'app-card-menu',
  templateUrl: './card-menu.component.html'
})
export class CardMenuComponent {

  @Input() sortedRoutes: Routes;

}
