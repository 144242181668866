<div class="card mb-0">
  <div class="card-header" *ngIf="exibirHeader">
    <div class="row">
      <h5 class="col mb-0 d-flex">Aviso</h5>
      <button type="button" class="btn rounded-pill btn-default col-auto" data-dismiss="modal"
              aria-label="Close" (click)="closeModal()">
        X
      </button>
    </div>
  </div>
  <div class="card-body">
    <h5 class="text-center">{{mensagem}}</h5>
  </div>

  <form [formGroup]="form">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <app-field
            [label]="label"
            [validateMessage]="validateMessage"
            [required]="isRequired('justificativa')"
            name="justificativa"
          >
              <textarea
                type="text"
                rows="4"
                formControlName="justificativa"
                maxlength="2000"
                class="form-control"
                [readOnly]="ehVisualizacao"
              >
              </textarea>
          </app-field>
        </div>
      </div>
    </div>
  </form>

  <div class="d-flex justify-content-center">
    <button
      type="submit"
      title="Cancelar"
      (click)="closeModal()"
      class="btn btn-danger rounded-pill mb-4">
      <em class="fa fa-times"></em>
      {{ nomeBotaoCancelar }}
    </button>
    <button
      *ngIf="!ehVisualizacao"
      type="submit"
      title="Salvar"
      (click)="emitirValorJustificativa()"
      [disabled]="enableShipping()"
      class="btn btn-primary rounded-pill mb-4 ml-2">
      <em class="fa fa-check"></em>
      {{ nomeBotaoOk }}
    </button>
  </div>
</div>
