<div [formGroup]="form">
  <div class="custom-file d-none">
    <input #file type="file" class="custom-file-input" [name]="name"
      [accept]="accept" (change)="onSelectFile($event)">
    <label class="custom-file-label" for="anexoContrato">Escolha um arquivo...</label>
  </div>
  <button  class="{{ classButton }}"
    (click)="file.click()" *ngIf="!viewAnexo()" [disabled]="isView && !canDownload">{{ nameButton }}
  </button>
  <div class="row flex align-items-center justify-content-center" *ngIf="viewAnexo()">
    <button id="downloadFile" title="Baixar o documento anexado" class="mx-2 btn btn-sm"
    (click)="canDownload ? download() : file.click()">
    <em class="fas fa-paperclip fa-lg"></em>
    {{ form.controls[name].value?.name  || form.controls[name].value?.nomeOriginal || form.controls[name].value?.nome }}
    </button>
    <button [disabled]="isView && !canDownload" class="btn rounded-pill btn-outline-danger border-0 rounded-circle" (click)="clear()" title="Remover documento" *ngIf="editable">
      <em class="fas fa-times"></em>
    </button>
    <button [disabled]="isView && !canDownload" class="btn rounded-pill btn-outline-primary border-0 rounded-circle" (click)="download()"
    title="Baixar o documento anexado" *ngIf="canDownload">
    <em class="fas fa-download"></em>
    </button>
  </div>
  <p class="text-center mt-2" [innerHTML]="legend"></p>
</div>
