<div class="table-responsive">
  <table
    #table
    id="table"
    class="table table-hover table-sm table-bordered mt-2 mb-2 w-100"
    aria-describedby="Tabela"
  >
    <thead id="thead">
      <ng-container *ngTemplateOutlet="appTableHeader">
        <th style="display: none;"></th>
      </ng-container>
    </thead>
    <tbody id="tbody">
      <ng-container
        *ngFor="let item of getItems | paginate: {id: paginationId,itemsPerPage: getItemsPerPage, currentPage: getCurrentPage, totalItems: getTotalItems };
        let index = index"
      >
        <ng-container *ngTemplateOutlet="appTableBody; context: {$implicit: item, index: index}"></ng-container>
      </ng-container>
      <ng-container *ngIf="getItems.length > 0">
        <ng-container *ngTemplateOutlet="appTableFooter"></ng-container>
      </ng-container>
      <ng-container *ngIf="getItems.length === 0">
          <ng-container *ngTemplateOutlet="appTableEmpty"></ng-container>
      </ng-container>
    </tbody>
  </table>
</div>
<app-paginator-custom
    *ngIf="paginator && !paginatorCustom"
    [total]="getTotalItems"
    [pageResponse]="page"
    [pageCount]="getCurrentPage"
    [stylesClass]="classPaginator"
    [items]="getItems"
    [itemsPerPage]="getItemsPerPage"
    [paginationId]="paginationId"
    (pageChange)="setCurrentPage($event)"
    (qtdRegistrosChange)="setQtdItems($event)">
</app-paginator-custom>
<app-paginator-custom-dinamic
  *ngIf="paginator && paginatorCustom"
  [total]="getTotalItems"
  [pageResponse]="page"
  [pageCount]="getCurrentPage"
  [stylesClass]="classPaginator"
  [items]="getItems"
  [itemsPerPage]="getItemsPerPage"
  (pageChange)="setCurrentPageDinamic($event)"
  (itemsPerPageChange)="setQtdItems($event)">
</app-paginator-custom-dinamic>
