import { Component, Input } from '@angular/core';
import { ArquivoCreateDto } from '@core/dto/arquivo/arquivo-create-dto';
import { ArquivoService } from '@core/services/arquivo.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Util } from '@shared/utils/util';
import { Message } from 'primeng';
import { ModalUploadSingleFileComponent } from '../modal-upload-single-file/modal-upload-single-file.component';

@Component({
  selector: 'app-file-attachment',
  templateUrl: './file-attachment.component.html'
})
export class FileAttachmentComponent {

  @Input() exibirBotaoAdicionarAnexo: boolean;
  @Input() anexos: any;
  msgs: Message[] = [];

  constructor(
    private modalService: NgbModal,
    private arquivoService: ArquivoService
  ) {}

  openModalAdicionarAnexo() {
    const modalRef = Util.openModal(this.modalService, ModalUploadSingleFileComponent, 'lg', { centered: true });
    modalRef.componentInstance.tituloModal = 'Adicionar Anexo';
    modalRef.componentInstance.saveAnexo.subscribe({
      next: (arquivo: any) => this.handleAdicionarAnexo(arquivo)
    });
  }

  handleAdicionarAnexo(arquivo: any) {
    if (this.anexos.length === 5) {
      this.msgs = [{severity: 'warn', summary: 'Atenção! Limite máximo de 5 arquivos atingido. Não é possível anexar mais arquivos.'}];
      setTimeout(() => {
        this.msgs = [];
      }, 5000);
      return;
    }

    const anexoJaAdicionado = this.anexos.filter((anexo: any) => anexo?.nome === arquivo.anexo.nome);
    if (anexoJaAdicionado.length > 0) {
      this.msgs = [{severity: 'warn', summary: 'Atenção! O arquivo selecionado já foi carregado anteriormente.'}];
      setTimeout(() => {
        this.msgs = [];
      }, 5000);
      return;
    }

    this.anexos.push(arquivo.anexo);
  }

  public baixarAnexo(item: any) {
    if (item.id) {
      this.visualizarPDFComCaminho(item);
      Util.baixarArquivoPeloId(item, this.arquivoService);
      return;
    }
    this.visualizarArquivoRecente(item);
    Util.baixarArquivoComBase64(item);
  }

  public removerAnexo(item: any) {
    this.anexos = this.anexos.filter((anexo: ArquivoCreateDto) => anexo.idControle !== item.idControle);
  }

  public visualizar(item) {
    if (item.id) {
      this.visualizarPDFComCaminho(item);
    } else {
      this.visualizarArquivoRecente(item);
    }
  }

  visualizarArquivoRecente(item) {
    const blob = Util.base64toBlob(item.base64, item.extensao);
    const url = window.URL.createObjectURL(blob);
    window.open(url, '_blank');
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 100);
  }

  visualizarPDFComCaminho(arquivo) {
    this.arquivoService.download(arquivo.arquivoId).subscribe((response: any) => {
      const type = Util.retornarTipoExtensao(arquivo.extensao);
      const blob = new Blob([response.body], { type });
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
      }, 100);
    });
  }

  public exibirIconeLixeira(item: any) {
    return !item.id;
  }
}
