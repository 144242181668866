import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html'
})
export class CardComponent {

  @Input() public title: string;
  @Input() public subtitle: string;
  @Input() public icon: string;
}
