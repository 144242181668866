import { Component, EventEmitter, Input, Output } from '@angular/core';

type CardQuantitativo = {
  title: string,
  quantidade: number,
  qtdDescription: string
};

@Component({
  selector: 'app-cards-quantitativos',
  templateUrl: './cards-quantitativos.component.html',
  styleUrls: ['./cards-quantitativos.component.scss']
})

export class CardsQuantitativosComponent {

  @Input() cards: CardQuantitativo[];
  @Output() cardClicked = new EventEmitter();

  filterCard(card) {
    this.cardClicked.emit(card.title);
  }
}
