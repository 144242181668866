<div class="dropdown" [class.show]="isDropdownOpen" >
  <button class="dropdown-toggle custom-dropdown" type="button" id="dropdownMenuButton" aria-haspopup="true" aria-expanded="false"
    (click)="toggleDropdown()"
    [ngClass]="{'disabled-button': disabledInput}"
  >
    {{ label }}
  </button>
  <div class="dropdown-tree" aria-labelledby="dropdownMenuButton" [class.show]="isDropdownOpen">
    <p-tree 
      [value]="itensArvore"
      selectionMode="checkbox"
      [(selection)]="selectedItens"
      (onNodeSelect)="nodeSelect($event)"
      (onNodeUnselect)="nodeUnselect($event)"
      [propagateSelectionDown]="true"
      [propagateSelectionUp]="true"
      [emptyMessage]="'Nenhum resultado encontrado'"
    ></p-tree>
  </div>
  <button class="backdrop" *ngIf="isDropdownOpen" (click)="toggleDropdown()"></button>
</div>