import { registerLocaleData } from '@angular/common';
import ptBr from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';
import { NgxMaskModule } from 'ngx-mask';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ChartModule } from 'primeng';
import { AppComponent } from './app.component';
import { APP_ROUTES } from './app.routing';

registerLocaleData(ptBr);

@NgModule({
  declarations: [AppComponent],
  imports: [
    RouterModule.forRoot(APP_ROUTES, { relativeLinkResolution: 'legacy' }),
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule.forRoot(),
    SharedModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    ChartModule,
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'pt' }],
  bootstrap: [AppComponent],
})
export class AppModule {}
