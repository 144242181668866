import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBase } from '@shared/utils/form-base';

@Component({
  selector: 'app-modal-upload-single-file',
  templateUrl: './modal-upload-single-file.component.html',
  styleUrls: ['./modal-upload-single-file.component.scss']
})
export class ModalUploadSingleFileComponent extends FormBase implements OnInit {

  @Output() saveAnexo = new EventEmitter();
  public tituloModal;
  public form: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    public activatedRoute: ActivatedRoute,
    public formBuilder: FormBuilder,
    public router: Router
  ) {
    super(router, activatedRoute);
  }

  ngOnInit(): void {
    this.createFormGroup();
  }

  public createFormGroup(): void {
    this.form = this.formBuilder.group({
      anexo: new FormControl(''),
    });
  }

  public closeModal() {
    this.activeModal.close();
  }

  public submit() {
    if (this.form.value?.anexo) {
      const anexoData = {
        anexo: this.form.value.anexo
      };
      this.saveAnexo.emit(anexoData);
      this.activeModal.close();
    }
  }

}
