import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioService } from '@core/services/usuario.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBase } from '../../utils/form-base';
import { Util } from '../../utils/util';
import { ValidatorsCustom } from '../../utils/validators-custom';
import { ModalRedigiteSenhaIncorretaComponent } from './modal-redigite-senha-incorreta/modal-redigite-senha-incorreta.component';

@Component({
  selector: 'app-modal-redigite-senha',
  templateUrl: './modal-redigite-senha.component.html'
})
export class ModalRedigiteSenhaComponent extends FormBase implements OnInit {

  senhaDigitada: string = '';
  exibirTitulo: boolean = false;
  btnConcluir: string = 'Assinar';
  maxCaracteres;

  constructor(
    public activeModal: NgbActiveModal,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private usuarioService: UsuarioService,
    private modalService: NgbModal
  ) {
    super(router, activatedRoute);
  }

  ngOnInit(): void {
    this.createFormGroup();
  }

  public createFormGroup(): void {
    this.form = this.formBuilder.group({
      password: new FormControl('', [ ValidatorsCustom.noWhitespaceValidator ])
    });
  }

  verificarSenha(): void {
    const params = Util.createFilter();
    params.append('senhaDigitada', this.form.get('password').value)
    this.usuarioService.verificarSenhaUserLogado(params)
      .subscribe((response) => {
        if (response){
          this.activeModal.close(response);
        }else{
          const modalRef = Util.openModal(this.modalService, ModalRedigiteSenhaIncorretaComponent, 'md', { centered: true });
          modalRef.componentInstance.mensagemModal = 'Senha incorreta. Verifique os dados informados e tente novamente.';
          modalRef.componentInstance.tituloModal = 'Atenção!';
          modalRef.componentInstance.mensagemBtn = 'Ok';
        }
      });
  }

  public closeModal() {
    this.activeModal.close();
  }

  enableShipping(): boolean {
    const senha = this.form.controls.password.value;
    if (senha?.length >= 3) {
      return false;
    }
    return true;
  }

}
