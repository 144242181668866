import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GenericChartData } from '../chart-types';

@Component({
  selector: 'app-advanced-pie-chart',
  templateUrl: './advanced-pie-chart.component.html',
  styleUrls: ['./advanced-pie-chart.component.scss']
})
export class AdvancedPieChartComponent {

  @Input() data: GenericChartData[] = [];
  @Input() view: any[] = [undefined, 230];
  @Input() label: string;
  colorScheme = {
    domain: ['#6095c7', '#213242', '#C7B42C', '#AAAAAA']
  };

  @Input() buttons: boolean = false;
  @Input() isPercentage: boolean = false;
  @Output() buttonClicked = new EventEmitter<string>();

  constructor() {
    Object.assign( this.data );
  }

  percentFormat(value) {
    return Math.round(value);
  }

  onClick(value: any) {
    this.buttonClicked.emit(value.textContent.toLowerCase().trim());
  }

}
