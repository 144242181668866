<div class="card card-primary mt-4 mx-3">
  <div class="card-body px-4">
    <div class="row">
      <div class="col-12">
        <div *ngIf="exibirBotaoAdicionarAnexo" class="mb-3">
          <button
            id="btn-adicionar-anexo"
            type="button"
            title="Adicionar Anexo"
            class="btn btn-primary rounded-pill"
            (click)="openModalAdicionarAnexo()">
            Selecionar Arquivo
          </button>
          <p-messages [(value)]="msgs" [closable]="false"></p-messages>
        </div>
        <app-table-custom
          [items]="anexos"
          [paginator]="false"
          paginationId="anexosList"
        >
          <ng-template appTableHeader>
            <tr>
              <th id="th-nome" class="text-center">Nome do Anexo</th>
              <th id="th-acoes" class="text-center">Ações</th>
            </tr>
          </ng-template>
          <ng-template appTableBody let-item>
            <tr>
              <td class="text-center">{{ item.nome }}</td>
              <td class="text-center">
                <button
                  id="btn-visualizar-normativa-criacao"
                  type="button"
                  class="btn btn-default btn-sm"
                  (click)="visualizar(item)"
                  title="Visualizar"
                >
                  <em class="fas fa-search"></em>
                </button>
                <button
                  type="button"
                  id="btn-download"
                  class="btn rounded-pill btn-default btn-sm"
                  title="Baixar anexo"
                  (click)="baixarAnexo(item)"
                >
                  <em class="fas fa-download"></em>
                </button>
                <button
                  *ngIf="exibirIconeLixeira(item)"
                  type="button"
                  id="btn-delete"
                  class="btn rounded-pill btn-default btn-sm"
                  title="Excluir"
                  (click)="removerAnexo(item)"
                >
                  <em class="fas fa-trash"></em>
                </button>
              </td>
            </tr>
          </ng-template>
        </app-table-custom>
      </div>
    </div>
  </div>
</div>
