import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-redigite-senha-incorreta',
  templateUrl: './modal-redigite-senha-incorreta.component.html'
})
export class ModalRedigiteSenhaIncorretaComponent {

  tituloModal: string = 'Aviso';
  mensagemModal: string = 'Senha Incorreta';
  mensagemBtn: string = 'Voltar';

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  public closeModal() {
    this.activeModal.close();
  }

}
